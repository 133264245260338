import Srdicko from "../images/gallery/srdicko.jpg";
import aperolOrange from "../images/gallery/aperol-alkoholicky-campari-orange.jpg";
import banhCuon from "../images/gallery/banh-cuon.jpg";
import banhChung from "../images/gallery/banh-chung.jpg";
import banhTom from "../images/gallery/banh-tom.jpg";
import banhTom1 from "../images/gallery/banh-tom-1.jpg";
import bunBoNamBo from "../images/gallery/bun-bo-nam-bo.jpg";
import bunChaObama from "../images/gallery/bun-cha-obama.jpg";
import capuchino from "../images/gallery/capucino-espresso.jpg";
import cremeBrulee from "../images/gallery/creme-brulee.jpg";
import cremeCaramel from "../images/gallery/creme-caramel.jpg";
import domaciLimo from "../images/gallery/domaci-limolada.jpg";
import hosoMakiAvokado from "../images/gallery/hoso-maki-avokado.jpg";
import hosoMakiSake from "../images/gallery/hoso-maki-sake.jpg";
import chuoiChien from "../images/gallery/chuoi-chien.jpg";
import mochi from "../images/gallery/mochi.jpg";
import mochi2 from "../images/gallery/mochi2.jpg";
import mojitoJahoda from "../images/gallery/mojito-jahoda.jpg";
import mrleBar from "../images/gallery/mrle-bar.jpg";
import mrleInterie from "../images/gallery/mrle-interie.jpg";
import mrleVanoce from "../images/gallery/mrle-vanoce.jpg";
import mrleVchod from "../images/gallery/mrle-vchod.jpg";
import napojOrange from "../images/gallery/napoj-orange.jpg";
import nemRan from "../images/gallery/nem-ran.jpg";
import oslavy from "../images/gallery/oslavy.jpg";
import oslavyNarozeniny from "../images/gallery/oslavy-narozeniny.jpg";
import phoBoGa from "../images/gallery/pho-bo-ga.jpg";
import sushiBox from "../images/gallery/sushi-box.jpg";
import sushiBoxCaviar from "../images/gallery/sushi-box-kaviar.jpg";
import sushiBoxMix from "../images/gallery/sushi-box-mix.jpg";
import sushiEbiTempura from "../images/gallery/sushi-ebi-tempura.jpg";
import sushiChien from "../images/gallery/sushi-chien.jpg";
import tomChien from "../images/gallery/tom-chien-rizecky.jpg";
import vietnamCafe from "../images/gallery/vietnamska-kava-kafephin.jpg";
import vietnamskyTet from "../images/gallery/vietnamsky-tet.jpg";
import DSC_7339 from "../images/gallery/DSC_7339.jpg";
import DSC_7340 from "../images/gallery/DSC_7340.jpg";
import DSC_7346 from "../images/gallery/DSC_7346.jpg";
import DSC_7349 from "../images/gallery/DSC_7349.jpg";
import DSC_7355 from "../images/gallery/DSC_7355.jpg";
import DSC_7358 from "../images/gallery/DSC_7358.jpg";
import DSC_7365 from "../images/gallery/DSC_7365.jpg";
import DSC_7369 from "../images/gallery/DSC_7369.jpg";
import DSC_7372 from "../images/gallery/DSC_7372.jpg";
import DSC_7375 from "../images/gallery/DSC_7375.jpg";
import DSC_7399 from "../images/gallery/DSC_7399.jpg";
import DSC_7407 from "../images/gallery/DSC_7407.jpg";
import DSC_7412 from "../images/gallery/DSC_7412.jpg";
import DSC_7416 from "../images/gallery/DSC_7416.jpg";
import DSC_7419 from "../images/gallery/DSC_7419.jpg";
import DSC_7425 from "../images/gallery/DSC_7425.jpg";
import DSC_7428 from "../images/gallery/DSC_7428.jpg";
import DSC_7429 from "../images/gallery/DSC_7429.jpg";
import DSC_7432 from "../images/gallery/DSC_7432.jpg";
import DSC_7433 from "../images/gallery/DSC_7433.jpg";
import DSC_7434 from "../images/gallery/DSC_7434.jpg";
import DSC_7444 from "../images/gallery/DSC_7444.jpg";
import DSC_7446 from "../images/gallery/DSC_7446.jpg";
import DSC_7454 from "../images/gallery/DSC_7454.jpg";
import DSC_7460 from "../images/gallery/DSC_7460.jpg";
import DSC_7464 from "../images/gallery/DSC_7464.jpg";
import DSC_7469 from "../images/gallery/DSC_7469.jpg";
import DSC_7473 from "../images/gallery/DSC_7473.jpg";
import DSC_7475 from "../images/gallery/DSC_7475.jpg";
import DSC_7478 from "../images/gallery/DSC_7478.jpg";
import DSC_7484 from "../images/gallery/DSC_7484.jpg";
import DSC_7491 from "../images/gallery/DSC_7491.jpg";
import DSC_7503 from "../images/gallery/DSC_7503.jpg";
import DSC_7509 from "../images/gallery/DSC_7509.jpg";
import DSC_7512 from "../images/gallery/DSC_7512.jpg";
import DSC_7516 from "../images/gallery/DSC_7516.jpg";
import DSC_7519 from "../images/gallery/DSC_7519.jpg";
import DSC_7525 from "../images/gallery/DSC_7525.jpg";
import DSC_7540 from "../images/gallery/DSC_7540.jpg";
import DSC_7547 from "../images/gallery/DSC_7547.jpg";
import DSC_7552 from "../images/gallery/DSC_7552.jpg";
import DSC_7554 from "../images/gallery/DSC_7554.jpg";
import DSC_7556 from "../images/gallery/DSC_7556.jpg";
import DSC_7557 from "../images/gallery/DSC_7557.jpg";
import DSC_7576 from "../images/gallery/DSC_7576.jpg";
import DSC_7614 from "../images/gallery/DSC_7614.jpg";
import DSC_7619 from "../images/gallery/DSC_7619.jpg";
import DSC_7625 from "../images/gallery/DSC_7625.jpg";
import DSC_7635 from "../images/gallery/DSC_7635.jpg";
import DSC_7653 from "../images/gallery/DSC_7653.jpg";
import DSC_7658 from "../images/gallery/DSC_7658.jpg";
import DSC_7671 from "../images/gallery/DSC_7671.jpg";
import DSC_7700 from "../images/gallery/DSC_7700.jpg";
import DSC_7712 from "../images/gallery/DSC_7712.jpg";
import DSC_7716 from "../images/gallery/DSC_7716.jpg";
import DSC_7721 from "../images/gallery/DSC_7721.jpg";
import DSC_7737 from "../images/gallery/DSC_7737.jpg";
import DSC_7744 from "../images/gallery/DSC_7744.jpg";
import DSC_7770 from "../images/gallery/DSC_7770.jpg";
import DSC_7786 from "../images/gallery/DSC_7786.jpg";
import DSC_7793 from "../images/gallery/DSC_7793.jpg";
import DSC_7836 from "../images/gallery/DSC_7836.jpg";
import DSC_7854 from "../images/gallery/DSC_7854.jpg";
import DSC_7885 from "../images/gallery/DSC_7885.jpg";
import DSC_7913 from "../images/gallery/DSC_7913.jpg";
import DSC_7917 from "../images/gallery/DSC_7917.jpg";
import DSC_7930 from "../images/gallery/DSC_7930.jpg";
import DSC_7963 from "../images/gallery/DSC_7963.jpg";
import DSC_7980 from "../images/gallery/DSC_7980.jpg";
import DSC_7981 from "../images/gallery/DSC_7981.jpg";
import DSC_7984 from "../images/gallery/DSC_7984.jpg";
import DSC_7997 from "../images/gallery/DSC_7997.jpg";
import DSC_8037 from "../images/gallery/DSC_8037.jpg";
import DSC_8052 from "../images/gallery/DSC_8052.jpg";
import DSC_8076 from "../images/gallery/DSC_8076.jpg";
import DSC_8092 from "../images/gallery/DSC_8092.jpg";
import DSC_8094 from "../images/gallery/DSC_8094.jpg";

export default [
  {
    src: DSC_7339,
    thumbnail: DSC_7339,
    caption: "",
  },
  {
    src: DSC_7340,
    thumbnail: DSC_7340,
    caption: "",
  },
  {
    src: DSC_7346,
    thumbnail: DSC_7346,
    caption: "",
  },
  {
    src: DSC_7349,
    thumbnail: DSC_7349,
    caption: "",
  },
  {
    src: DSC_7355,
    thumbnail: DSC_7355,
    caption: "",
  },
  {
    src: DSC_7358,
    thumbnail: DSC_7358,
    caption: "",
  },
  {
    src: DSC_7365,
    thumbnail: DSC_7365,
    caption: "",
  },
  {
    src: DSC_7369,
    thumbnail: DSC_7369,
    caption: "",
  },
  {
    src: DSC_7372,
    thumbnail: DSC_7372,
    caption: "",
  },
  {
    src: DSC_7375,
    thumbnail: DSC_7375,
    caption: "",
  },
  {
    src: DSC_7399,
    thumbnail: DSC_7399,
    caption: "",
  },
  {
    src: DSC_7407,
    thumbnail: DSC_7407,
    caption: "",
  },
  {
    src: DSC_7412,
    thumbnail: DSC_7412,
    caption: "",
  },
  {
    src: DSC_7416,
    thumbnail: DSC_7416,
    caption: "",
  },
  {
    src: DSC_7419,
    thumbnail: DSC_7419,
    caption: "",
  },
  {
    src: DSC_7425,
    thumbnail: DSC_7425,
    caption: "",
  },
  {
    src: DSC_7428,
    thumbnail: DSC_7428,
    caption: "",
  },
  {
    src: DSC_7429,
    thumbnail: DSC_7429,
    caption: "",
  },
  {
    src: DSC_7432,
    thumbnail: DSC_7432,
    caption: "",
  },
  {
    src: DSC_7433,
    thumbnail: DSC_7433,
    caption: "",
  },
  {
    src: DSC_7434,
    thumbnail: DSC_7434,
    caption: "",
  },
  {
    src: DSC_7444,
    thumbnail: DSC_7444,
    caption: "",
  },
  {
    src: DSC_7446,
    thumbnail: DSC_7446,
    caption: "",
  },
  {
    src: DSC_7454,
    thumbnail: DSC_7454,
    caption: "",
  },
  {
    src: DSC_7460,
    thumbnail: DSC_7460,
    caption: "",
  },
  {
    src: DSC_7464,
    thumbnail: DSC_7464,
    caption: "",
  },
  {
    src: DSC_7469,
    thumbnail: DSC_7469,
    caption: "",
  },
  {
    src: DSC_7473,
    thumbnail: DSC_7473,
    caption: "",
  },
  {
    src: DSC_7475,
    thumbnail: DSC_7475,
    caption: "",
  },
  {
    src: DSC_7478,
    thumbnail: DSC_7478,
    caption: "",
  },
  {
    src: DSC_7484,
    thumbnail: DSC_7484,
    caption: "",
  },
  {
    src: DSC_7491,
    thumbnail: DSC_7491,
    caption: "",
  },
  {
    src: DSC_7503,
    thumbnail: DSC_7503,
    caption: "",
  },
  {
    src: DSC_7509,
    thumbnail: DSC_7509,
    caption: "",
  },
  {
    src: DSC_7512,
    thumbnail: DSC_7512,
    caption: "",
  },
  {
    src: DSC_7516,
    thumbnail: DSC_7516,
    caption: "",
  },
  {
    src: DSC_7519,
    thumbnail: DSC_7519,
    caption: "",
  },
  {
    src: DSC_7525,
    thumbnail: DSC_7525,
    caption: "",
  },
  {
    src: DSC_7540,
    thumbnail: DSC_7540,
    caption: "",
  },
  {
    src: DSC_7547,
    thumbnail: DSC_7547,
    caption: "",
  },
  {
    src: DSC_7552,
    thumbnail: DSC_7552,
    caption: "",
  },
  {
    src: DSC_7554,
    thumbnail: DSC_7554,
    caption: "",
  },
  {
    src: DSC_7556,
    thumbnail: DSC_7556,
    caption: "",
  },
  {
    src: DSC_7557,
    thumbnail: DSC_7557,
    caption: "",
  },
  {
    src: DSC_7576,
    thumbnail: DSC_7576,
    caption: "",
  },
  {
    src: DSC_7614,
    thumbnail: DSC_7614,
    caption: "",
  },
  {
    src: DSC_7619,
    thumbnail: DSC_7619,
    caption: "",
  },
  {
    src: DSC_7625,
    thumbnail: DSC_7625,
    caption: "",
  },
  {
    src: DSC_7635,
    thumbnail: DSC_7635,
    caption: "",
  },
  {
    src: DSC_7653,
    thumbnail: DSC_7653,
    caption: "",
  },
  {
    src: DSC_7658,
    thumbnail: DSC_7658,
    caption: "",
  },
  {
    src: DSC_7671,
    thumbnail: DSC_7671,
    caption: "",
  },
  {
    src: DSC_7700,
    thumbnail: DSC_7700,
    caption: "",
  },
  {
    src: DSC_7712,
    thumbnail: DSC_7712,
    caption: "",
  },
  {
    src: DSC_7716,
    thumbnail: DSC_7716,
    caption: "",
  },
  {
    src: DSC_7721,
    thumbnail: DSC_7721,
    caption: "",
  },
  {
    src: DSC_7737,
    thumbnail: DSC_7737,
    caption: "",
  },
  {
    src: DSC_7744,
    thumbnail: DSC_7744,
    caption: "",
  },
  {
    src: DSC_7770,
    thumbnail: DSC_7770,
    caption: "",
  },
  {
    src: DSC_7786,
    thumbnail: DSC_7786,
    caption: "",
  },
  {
    src: DSC_7793,
    thumbnail: DSC_7793,
    caption: "",
  },
  {
    src: DSC_7836,
    thumbnail: DSC_7836,
    caption: "",
  },
  {
    src: DSC_7854,
    thumbnail: DSC_7854,
    caption: "",
  },
  {
    src: DSC_7885,
    thumbnail: DSC_7885,
    caption: "",
  },
  {
    src: DSC_7913,
    thumbnail: DSC_7913,
    caption: "",
  },
  {
    src: DSC_7917,
    thumbnail: DSC_7917,
    caption: "",
  },
  {
    src: DSC_7930,
    thumbnail: DSC_7930,
    caption: "",
  },
  {
    src: DSC_7963,
    thumbnail: DSC_7963,
    caption: "",
  },
  {
    src: DSC_7980,
    thumbnail: DSC_7980,
    caption: "",
  },
  {
    src: DSC_7981,
    thumbnail: DSC_7981,
    caption: "",
  },
  {
    src: DSC_7984,
    thumbnail: DSC_7984,
    caption: "",
  },
  {
    src: DSC_7997,
    thumbnail: DSC_7997,
    caption: "",
  },
  {
    src: DSC_8037,
    thumbnail: DSC_8037,
    caption: "",
  },
  {
    src: DSC_8052,
    thumbnail: DSC_8052,
    caption: "",
  },
  {
    src: DSC_8076,
    thumbnail: DSC_8076,
    caption: "",
  },
  {
    src: DSC_8092,
    thumbnail: DSC_8092,
    caption: "",
  },
  {
    src: DSC_8094,
    thumbnail: DSC_8094,
    caption: "",
  },
  {
    src: Srdicko,
    thumbnail: Srdicko,
    caption: "Srdičko - VYHRAJME SPOLEČNĚ PROTI KORONA VIRU",
  },
  {
    src: aperolOrange,
    thumbnail: aperolOrange,
    caption: "Aperol alkoholický campari orange",
  },
  {
    src: domaciLimo,
    thumbnail: domaciLimo,
    caption: "Domácí limoláda",
  },
  {
    src: mojitoJahoda,
    thumbnail: mojitoJahoda,
    caption: "Mojito jahoda",
  },
  {
    src: napojOrange,
    thumbnail: napojOrange,
    caption: "Nápoj orange",
  },
  {
    src: capuchino,
    thumbnail: capuchino,
    caption: "Cappuccino & espresso",
  },
  {
    src: cremeBrulee,
    thumbnail: cremeBrulee,
    caption: "Crème brûlée",
  },
  {
    src: cremeCaramel,
    thumbnail: cremeCaramel,
    caption: "Crème caramel",
  },
  {
    src: vietnamCafe,
    thumbnail: vietnamCafe,
    caption: "Vietnamská káva kafephin",
  },
  {
    src: chuoiChien,
    thumbnail: chuoiChien,
    caption: "Chuoi chien",
  },
  {
    src: mochi,
    thumbnail: mochi,
    caption: "Mochi",
  },
  {
    src: mochi2,
    thumbnail: mochi2,
    caption: "Mochi",
  },
  {
    src: nemRan,
    thumbnail: nemRan,
    caption: "Jarní závitek",
  },
  {
    src: tomChien,
    thumbnail: tomChien,
    caption: "Tom chien řízečky",
  },
  {
    src: phoBoGa,
    thumbnail: phoBoGa,
    caption: "Pho Bo Ga",
  },
  {
    src: bunBoNamBo,
    thumbnail: bunBoNamBo,
    caption: "Bun Bo Nam Bo",
  },
  {
    src: bunChaObama,
    thumbnail: bunChaObama,
    caption: "Bun Cha Obama",
  },
  {
    src: banhCuon,
    thumbnail: banhCuon,
    caption: "Banh Cuon",
  },
  {
    src: banhTom1,
    thumbnail: banhTom1,
    caption: "Banh Tom",
  },
  {
    src: banhTom,
    thumbnail: banhTom,
    caption: "Banh Tom",
  },
  {
    src: banhChung,
    thumbnail: banhChung,
    caption: "Banh Chung",
  },
  {
    src: hosoMakiAvokado,
    thumbnail: hosoMakiAvokado,
    caption: "Hoso Maki Avokádo",
  },
  {
    src: hosoMakiSake,
    thumbnail: hosoMakiSake,
    caption: "Hoso Maki Sake",
  },
  {
    src: sushiEbiTempura,
    thumbnail: sushiEbiTempura,
    caption: "Sushi Ebi Tempura",
  },
  {
    src: sushiChien,
    thumbnail: sushiChien,
    caption: "Sushi Chien",
  },
  {
    src: sushiBox,
    thumbnail: sushiBox,
    caption: "Sushi Box",
  },
  {
    src: sushiBoxCaviar,
    thumbnail: sushiBoxCaviar,
    caption: "Sushi Box Kaviár",
  },
  {
    src: sushiBoxMix,
    thumbnail: sushiBoxMix,
    caption: "Sushi Box Mix",
  },
  {
    src: oslavy,
    thumbnail: oslavy,
    caption: "Oslavy narozenin",
  },
  {
    src: oslavyNarozeniny,
    thumbnail: oslavyNarozeniny,
    caption: "Oslavy narozenin",
  },
  {
    src: mrleBar,
    thumbnail: mrleBar,
    caption: "Bar",
  },
  {
    src: mrleInterie,
    thumbnail: mrleInterie,
    caption: "Interiér",
  },
  {
    src: mrleVchod,
    thumbnail: mrleVchod,
    caption: "Vchod",
  },
  {
    src: mrleVanoce,
    thumbnail: mrleVanoce,
    caption: "Vánoce",
  },
  {
    src: vietnamskyTet,
    thumbnail: vietnamskyTet,
    caption: "Vietnamský Tet",
  },
];
